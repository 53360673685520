<touch-here></touch-here>
<driver></driver>

<div class="content">
    <router-outlet> </router-outlet>
    <walrus-player></walrus-player>
    <walrus-map></walrus-map>

    <div class="debug" *ngIf="debug">
        <hr class="horizontal" />
        <hr class="horizontal" />
        <hr class="vertical" />
        <hr class="vertical" />
    </div>
</div>
