import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import {
    ButtonComponent,
    DriverComponent,
    FilterComponent,
    HeaderComponent,
    ModalComponent,
    TouchHereComponent,
    VideoComponent,
} from "./components";

@NgModule({
    bootstrap: [],
    declarations: [
        ButtonComponent,
        DriverComponent,
        FilterComponent,
        HeaderComponent,
        ModalComponent,
        VideoComponent,
        TouchHereComponent,
    ],
    exports: [
        ButtonComponent,
        DriverComponent,
        FilterComponent,
        HeaderComponent,
        ModalComponent,
        VideoComponent,
        TouchHereComponent,
    ],
    imports: [CommonModule],
    providers: [],
})
export class SharedModule {}
