import { Component, HostListener } from "@angular/core";
import { interval, Subject } from "rxjs";
import { startWith, switchMap, takeUntil, tap } from "rxjs/operators";

@Component({
    selector: "touch-here",
    styleUrls: ["./touch-here.component.scss"],
    templateUrl: "./touch-here.component.html",
})
export class TouchHereComponent {
    public istouchMeActive = false;
    private readonly reset$ = new Subject<void>();
    private isTimerRunning = false;
    private readonly inactivityDelay = 40; // en seconde

    public constructor() {
        this.startTimer();
    }

    @HostListener("document:mousemove")
    @HostListener("document:click")
    public interactAction() {
        this.istouchMeActive = false;
        this.startTimer();
    }

    public activateScreensaver() {
        this.istouchMeActive = true;
        this.isTimerRunning = false;
        this.reset$.next();
    }

    private startTimer() {
        if (this.isTimerRunning) {
            this.reset$.next();

            return;
        }

        this.isTimerRunning = true;
        this.reset$
            .pipe(
                startWith(undefined),
                switchMap(() => interval(1000).pipe(takeUntil(this.reset$))),
            )
            .subscribe((count: number) => {
                if (count + 1 >= this.inactivityDelay) {
                    this.activateScreensaver();
                }
            });
    }
}
