<div *ngIf="istouchMeActive" class="touch-here--container">
    <div class="badge">
        <svg width="400" height="400" viewBox="0 0 400 400">
            <defs>
                <path id="textPath1" d="M 200, 200 m -150, 0 a 150,150 0 1,1 300,0 a 150,150 0 1,1 -300,0" />
                <path id="textPath2" d="M 200, 200 m -175, 0 a 175,175 0 1,0 350,0 a 175,175 0 1,0 -350,0" />
            </defs>
            <text class="curved-text">
                <textPath href="#textPath1" startOffset="0%">Ecran Tactile</textPath>
            </text>
            <text class="curved-text">
                <textPath href="#textPath2" startOffset="10%">Ecran Tactile</textPath>
            </text>
        </svg>
        <div class="badge-icon">
            <i class="mv-icons--design--phone" style="font-size: 5.5em"></i>
        </div>
    </div>
</div>
